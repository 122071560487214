import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { initPagination } from "../lib/prop_pagination"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { adHelper } from "../lib/ad_helper"
import { PropertyCard } from "./PropertyCard"

export const TopContent = ({ filters } = {}) => {
  const state = useContext(GlobalStateContext)
  useEffect(() => {
    if (state.isReady) {
      initPagination()
    }
  }, [state.isReady])
  return (
    <div className="top-content-section properties-listing-page-section section clearfix position-relative">
      <div className="container clearfix">
        <div className="title-1-holder clearfix mb-md-5 mb-3 pb-md-5">
          <h2 className="title-1 text-uppercase" data-aos="fade-in">
            <span className="text-black-50">Listed </span>Properties
          </h2>
        </div>
        <div className="row clearfix">
          {adHelper.filterAds(filters).map(prop => (
            <PropertyCard key={prop.id} property={prop} />
          ))}
          {/* <Pagination /> */}
        </div>
      </div>
      {/* <Assurances /> */}
    </div>
  )
}

TopContent.propTypes = {
  filters: PropTypes.any,
}


