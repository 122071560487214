import React, { useState } from "react"
import { Layout } from "../../components/Layout"
import { PropertyBanner } from "../../components/PropertyBanner"
import { PropertyTypeSlider } from "../../components/PropertyTypeSlider"
import { PropertyFilters } from "../../components/PropertyFilters"
import { TopContent } from "../../components/TopContent";
import { adHelper } from "../../lib/ad_helper"
import { data } from "../../lib/data"

export default function Properties() {
  const [filters, setFilters] = useState({});
  return (
    <Layout className="props-listing-page">
      <PropertyBanner agentEmail={data.userProfile.userEmail} agentPhone={data.userProfile.phone} />
      {adHelper.uniqueDwellingTypes().length > 1 ? (
        <PropertyTypeSlider onTypeSelected={type => setFilters({...filters, type}) } />
       ) : null}
      <PropertyFilters onFiltersChanged={f => setFilters({...filters, ...f})} />
      <TopContent filters={filters} />
      {/* <div style={{marginTop: '200px'}}><pre>{JSON.stringify(filters,null,2)}</pre></div> */}
    </Layout>
  )
}