import PropTypes from "prop-types";
import React from "react";

export const PropertyTypeCard = ({ proptype, count, onSelect = () => { } }) => (
  <div onClick={() => onSelect(proptype)} className="item about-us-prop-type-slider-1-item clearfix">
    <a className="about-us-prop-type-slider-1-inner clearfix d-flex flex-wrap text-decoration-none align-items-center">
      <div className="about-us-prop-type-slider-col about-us-prop-type-slider-col-left">
        <img
          src={`/images/types/${proptype}.jpg`}
          alt={proptype}
          className="w-auto mw-100" />
      </div>
      <div className="about-us-prop-type-slider-col about-us-prop-type-slider-col-right">
        <h5 className="text-white font-16 mb-2">{proptype}</h5>
        <p className="text-white opacity-0-7 font-13 text-uppercase">Explore</p>
        <span className="prop-types-counter">{count}</span>
      </div>
    </a>
  </div>
);
PropertyTypeCard.propTypes = {
  count: PropTypes.number,
  onSelect: PropTypes.func,
  proptype: PropTypes.string
};
