import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { adHelper } from "../lib/ad_helper";
import { PropertyTypeCard } from "./PropertyTypeCard";

export const PropertyTypeSlider = ({onTypeSelected = () => {}}) => {
  const state = useContext(GlobalStateContext)
  useEffect(() => {
    if (state.isReady) {
      // eslint-disable-next-line no-undef
      if (typeof $ == "undefined") return
      // eslint-disable-next-line no-undef
      var about_prop_type_slider1 = $("#about-us-prop-type-slider-1")
      about_prop_type_slider1.owlCarousel({
        items: 5,
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        smartSpeed: 850,
        autoplay: 2000,
        autoplayHoverPause: true,
        responsive: {
          300: {
            items: 1,
          },
          500: {
            items: 2,
          },
          768: {
            items: 3,
          },
          1024: {
            items: 4,
          },
          1300: {
            items: 5,
          },
        },
      })
    }
  }, [state.isReady])
  const uniqTypes = adHelper.uniqueDwellingTypes();
  return (
    <div className="section about-us-prop-type-slider-section clearfix py-4">
      <div
        className="owl-carousel owl-theme about-us-prop-type-slider-1"
        id="about-us-prop-type-slider-1"
      >
        {Object.keys(uniqTypes).map(t => <PropertyTypeCard key={t} proptype={t} count={uniqTypes[t]} onSelect={onTypeSelected}/>)} 
      </div>
    </div>
  )
}

PropertyTypeSlider.propTypes = {
  onTypeSelected: PropTypes.func
}


