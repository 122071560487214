/* eslint-disable no-undef */

export const initPagination = () => {
  $(".pagi-1-link-arrows")
  .mouseenter(function () {
      $(".pagi-1-link-arrows").removeClass("active")
      $(this).addClass("active")
    })
    .mouseleave(function () {
      $(".pagi-1-link-arrows").removeClass("active")
      $(".pagi-1-link-arrows.pagi-1-link-arrows-right").addClass("active")
    })
}