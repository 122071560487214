import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { data } from "../lib/data"
import Slider from 'rc-slider'
import { adHelper } from "../lib/ad_helper";
import { Link as ScrollLink } from 'react-scroll';

const Range = Slider.createSliderWithTooltip(Slider.Range);

export const PropertyFilters = ({ onFiltersChanged = () => {} }) => {
  const [filters, setFilters] = useState({
    agent: "",
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      const search = window.location.search
      const params = new URLSearchParams(search)
      setFilters({ ...filters, agent: params.get("agent") || "" })
    }
  }, [])

  useEffect(() => {
    onFiltersChanged(filters)
  }, [filters])

  return (
    <div className="section properties-filter-section clearfix position-relative">
      <div
        className="container clearfix properties-filter-section-container bg-light px-5 pt-5 pb-3"
        data-aos="fade-up"
        data-aos-delay="800"
      >
        <div className="row clearfix">
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Agent :
              </label>
              <div className="form-group clearfix properties-form-group properties-form-group-has-select position-relative">
                <select
                  value={filters.agent}
                  onChange={e => setFilters({...filters, agent: e.target.value})}
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                >
                  <option value="">all agents</option>
                  {Object.keys(data.agentDetails).map(a => (
                    <option key={a}>{a}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Category :
              </label>
              <div className="form-group clearfix properties-form-group properties-form-group-has-select position-relative">
                <select
                  onChange={e => setFilters({ ...filters, category: e.target.value })}
                  value={filters.category}
                  defaultValue="all status"
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                >
                  <option value="">all categories</option>
                  <option value="9075">Flat Share &amp; House Share</option>
                  <option value="9071">Houses &amp; Flats For Rent</option>
                  <option value="9074" selected="selected">Houses &amp; Flats For Sale</option>
                  <option value="9070">Holiday Homes</option>
                  <option value="9137">Land &amp; Plots For Sale</option>
                  <option value="9073">Car Parks &amp; Storage</option>
                  <option value="9239">Commercial - Office Space</option>
                  <option value="9240">Commercial - Retail Space</option>
                  <option value="9241">Commercial - Industrial Property</option>
                  <option value="9001">Short Term</option>
                  <option value="9008">Other Property</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Location :
              </label>
              <div className="form-group clearfix properties-form-group position-relative">
                <input
                  defaultValue=""
                  value={filters.location}
                  onChange={e => setFilters({ ...filters, location: e.target.value })}
                  type="text"
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                  placeholder="enter location"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Bedroom :
              </label>
              <div className="form-group clearfix properties-form-group properties-form-group-has-select position-relative">
                <select
                  value={filters.bedroom}
                  onChange={e => setFilters({ ...filters, bedroom: e.target.value })}
                  defaultValue="1"
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                >
                  <option value="1">1 or more</option>
                  <option value="2">2 or more</option>
                  <option value="3">3 or more</option>
                  <option value="4">4 or more</option>
                  <option value="5">5 or more</option>
                  <option value="6">6 or more</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Bathroom :
              </label>
              <div className="form-group clearfix properties-form-group properties-form-group-has-select position-relative">
                <select
                  value={filters.bathroom}
                  onChange={e => setFilters({...filters, bathroom: e.target.value})}
                  defaultValue="1"
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                >
                  <option value="1">1 or more</option>
                  <option value="2">2 or more</option>
                  <option value="3">3 or more</option>
                  <option value="4">4 or more</option>
                  <option value="5">5 or more</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                keyword :
              </label>
              <div className="form-group clearfix properties-form-group position-relative">
                <input
                  value={filters.keyword}
                  onChange={e => setFilters({...filters, keyword: e.target.value})}
                  type="text"
                  className="form-control properties-filter-control shadow-none border-0 font-AvenirLTStd-Roman"
                  placeholder="enter your keyword"
                />
              </div>
            </div>
          </div>
           <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Price (R mil) :
              </label>
              <div className="form-group clearfix properties-form-group position-relative properties-form-group-has-range-slider mt-3">
                <div className="slider-wrapper range-slider-green-1">
                  <Range value={filters.price} onChange={value => setFilters({...filters, price: value})} defaultValue={[0,15]} min={0} max={15} step={0.1} handleStyle={{borderColor: '#8fd24c'}} trackStyle={{backgroundColor: '#8fd24c'}} />
                  <div className="range-slider-rate-holder clearfix d-flex flex-wrap align-items-center justify-content-between">
                    <p className="range-slider-rate range-slider-rate-min opacity-0-5">
                      0
                    </p>
                    <p className="range-slider-rate range-slider-rate-max ml-auto mr-0 opacity-0-5">
                      15 mil
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="col-sm-6 col-lg-4 clearfix properties-filter-section-col mb-2">
            {/* <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-3">
                Area (SQM) :
              </label>
              <div className="form-group clearfix properties-form-group position-relative properties-form-group-has-range-slider mt-3">
                <div className="slider-wrapper range-slider-green-1">
                <Range value={filters.area} onChange={value => setFilters({...filters, area: value})} defaultValue={[0,2000]} min={0} max={2000} step={50} handleStyle={{borderColor: '#8fd24c'}} trackStyle={{backgroundColor: '#8fd24c'}} />
                 
                  <div className="range-slider-rate-holder clearfix d-flex flex-wrap align-items-center justify-content-between">
                    <p className="range-slider-rate range-slider-rate-min opacity-0-5">
                      0
                    </p>
                    <p className="range-slider-rate range-slider-rate-max ml-auto mr-0 opacity-0-5">
                      2000
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-sm-12 col-lg-4 clearfix properties-filter-section-col mb-2">
            <div className="properties-filter-section-col-inner clearfix">
              <label className="font-TitilliumWeb-Regular properties-filter-control-label font-16 text-uppercase font-weight-bold mb-0">
                &nbsp;
              </label>
              <div className="form-group clearfix properties-form-group position-relative">
                <ScrollLink to="top-content-section" spy={true} smooth={true} className="btn btn-transparent border-green-1 border-2 rounded-0 w-100 font-Poppins-Medium text-uppercase filter-btn-1">
                  Found {adHelper.filterAds(filters).length} Properties{" "}
                  <img
                    src="/images/long-arrow-right-black.png"
                    alt="right-arrow"
                    className="ml-3"
                  />
                </ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PropertyFilters.propTypes = {
  onFiltersChanged: PropTypes.func,
}
