import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";
import { adHelper } from "../lib/ad_helper";
import { accounting } from "accounting";

export const PropertyCard = ({ property }) => (
  <div className="properties-slider-1-item col-md-6 mb-4" data-aos="fade-up">
    <div
      className="properties-slider-1-item-inner clearfix"
      onClick={() => navigate(adHelper.propertyLink(property))}
    >
      <div className="properties-slider-1-img-holder clearfix">
        <img
          src={adHelper.firstLargePictureUrl(property)}
          alt="property"
          className="object-fit-cover h-100 w-100" />
      </div>
      <div className="properties-slider-1-cntnt-holder clearfix">
        <div className="properties-slider-1-cntnt-holder-inner clearfix">
          <p className="properties-slider-1-price text-black font-15 font-TitilliumWeb-Bold mb-3">
            {accounting.formatMoney(property.price.amount, "R")}
          </p>
          <h4 className="font-28 font-Archivo-Medium mb-3 properties-slider-1-title">
            {property.title}
          </h4>
          <p className="font-AvenirLTStd-Medium properties-slider-1-content mb-3">
            {property.locations.location.localizedName}
          </p>
          <a
            className="font-AvenirLTStd-Medium properties-slider-1-link"
            href={adHelper.propertyLink(property)}
          >
            Details
          </a>
        </div>
      </div>
    </div>
  </div>
);
PropertyCard.propTypes = {
  property: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    locations: PropTypes.shape({
      location: PropTypes.shape({
        localizedName: PropTypes.string
      })
    }),
    price: PropTypes.shape({
      amount: PropTypes.number
    }),
    title: PropTypes.string
  })
}
